var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-end-campaign"},[_c('a-modal',{staticClass:"modal-end-campaign",class:[_vm.currentLocale],attrs:{"footer":null,"closeIcon":true,"centered":"","width":"380px","afterClose":_vm.modalClose},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/images/userlevel/Trash_Red.svg"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.lbl['user-level-stop-this-campaign']))])]),_c('div',{staticClass:"box-details my-4"},[_c('span',[_vm._v(_vm._s(_vm.lbl['user-level-stop-this-campaign-details']))])]),_c('div',{staticClass:"pass-word"},[_c('span',[_vm._v(_vm._s(_vm.lbl['user-level-enter-your-email']))]),_c('a-form-item',{attrs:{"validate-status":_vm.email_status,"help":_vm.email_message}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    rules: [
                      {
                        initialValue: _vm.email,
                      } ],
                  } ]),expression:"[\n                  'email',\n                  {\n                    rules: [\n                      {\n                        initialValue: email,\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"mt-2",attrs:{"placeholder":_vm.lbl['user-level-placeholder-email']},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('a-icon',{attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})]},proxy:true}])})],1)],1),_c('b-button',{staticClass:"btn-end-campaign mt-4",attrs:{"variant":"danger","block":""},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(" "+_vm._s(_vm.lbl['user-level-comfirm-to-stop-user-level'])+" ")]),_c('b-button',{staticClass:"btn-cancel-end-campaign",attrs:{"variant":"outline-primary mt-3 mb-3","block":""},on:{"click":function($event){return _vm.modalClose()}}},[_vm._v(" "+_vm._s(_vm.lbl['user-level-button-cancel'])+" ")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }