<template>
  <div class="page-end-campaign">
    <a-modal
      v-model="showModal"
      :footer="null"
      :closeIcon="true"
      centered
      width="380px"
      class="modal-end-campaign"
      :class="[currentLocale]"
      :afterClose="modalClose"
    >
      <a-form :form="form">
        <div class="row">
          <div class="col-12">
            <span class="title">
              <img src="@/assets/images/userlevel/Trash_Red.svg" alt="" />
              <span>{{ lbl['user-level-stop-this-campaign'] }}</span>
            </span>

            <div class="box-details my-4">
              <span>{{ lbl['user-level-stop-this-campaign-details'] }}</span>
            </div>

            <div class="pass-word">
              <span>{{ lbl['user-level-enter-your-email'] }}</span>
              <a-form-item
                :validate-status="email_status"
                :help="email_message"
              >
                <a-input
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        {
                          initialValue: email,
                        },
                      ],
                    },
                  ]"
                  :placeholder="lbl['user-level-placeholder-email']"
                  class="mt-2"
                >
                  <template #prefix>
                    <a-icon slot="prefix" type="mail" />
                  </template>
                </a-input>
              </a-form-item>
            </div>

            <b-button
              variant="danger"
              class="btn-end-campaign mt-4"
              block
              @click="handleSubmit()"
            >
              {{ lbl['user-level-comfirm-to-stop-user-level'] }}
            </b-button>

            <b-button
              variant="outline-primary mt-3 mb-3"
              class="btn-cancel-end-campaign"
              block
              @click="modalClose()"
            >
              {{ lbl['user-level-button-cancel'] }}
            </b-button>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Account from '@/helper/AccountHelper.js'
import BzbsUserProfile from '@/core/UserProfile/service/BzbsUserProfile'
import Locale from '@/helper/locale.js'

export default {
  name: 'EndCampaign',
  mixins: [Mixin],
  props: {
    show: Boolean,
  },
  data() {
    return {
      currentLocale: Locale.getLocaleShort(),
      showModal: false,
      email: '',
      email_status: '',
      email_message: '',
      profile: Account.getCacheProfile(),
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'end-campaign' })
  },
  created() {
    this.init()
  },
  watch: {
    show(newVal, oldVal) {
      console.log('Prop show: ', newVal, ' | was: ', oldVal)
      if (newVal == true) {
        this.form.resetFields()
        this.showModal = newVal
      }
    },
  },
  methods: {
    init() {
      this.email = ''
      this.email_status = ''
      this.email_message = ''
      this.form.resetFields()
    },
    modalClose() {
      this.showModal = false
      this.email = ''
      this.email_status = ''
      this.email_message = ''
      this.form.resetFields()
      this.$emit('changeEndCampaign', false)
    },
    checkFormatEmail(e) {
      if (!e) {
        this.email_status = 'error'
        this.email_message = this.lbl['user-level-required-email']
        return false
      } else if (!this.validEmail(e)) {
        this.email_status = 'error'
        this.email_message = this.lbl['user-level-format-email']
        return false
      } else {
        this.email_status = ''
        this.email_message = ''
        return true
      }
    },
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    matchEmail(e) {
      if (this.profile.strEmail == e) {
        this.email_status = ''
        this.email_message = ''
        return true
      } else {
        this.email_status = 'error'
        this.email_message = this.lbl['user-level-email-not-match']
        return false
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err && this.checkFormatEmail(values.email)) {
          if (this.matchEmail(values.email)) {
            return new Promise(resolve => {
              BzbsUserProfile.postEndCampaign()
                .then(res => {
                  if (res != null) {
                    //cancel publish user level
                    if (res.data[0]['LevelStatus'].toLowerCase() == 'active') {
                      BzbsUserProfile.postCancelPublishgroup(
                        res.data[0]['GroupCreate'],
                      )
                        .then(res => {
                          console.log('postCancelPublishgroup success', res)
                        })
                        .catch(error => {
                          console.log('postCancelPublishgroup error', error)
                        })
                    }

                    this.$message.success({
                      content:
                        this.lbl[
                          'user-level-message-success-end-this-campaign'
                        ],
                      top: '100px',
                      duration: 3,
                      maxCount: 3,
                    })
                    this.$emit('changeEndCampaign', true)
                  } else {
                    this.$message.error({
                      content:
                        this.lbl[
                          'user-level-message-not-success-end-this-campaign'
                        ],
                      top: '100px',
                      duration: 3,
                      maxCount: 3,
                    })
                    this.$emit('changeEndCampaign', false)
                  }
                  resolve(res.data)
                })
                .catch(error => {
                  this.$message.error({
                    content:
                      this.lbl[
                        'user-level-message-not-success-end-this-campaign'
                      ],
                    top: '100px',
                    duration: 3,
                    maxCount: 3,
                  })
                  this.$emit('changeEndCampaign', false)
                  resolve(error)
                })
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
.modal-end-campaign {
  .ant-modal-content {
    border-radius: 4px;
  }

  .btn-end-campaign {
    height: 45px;
  }

  .btn-cancel-end-campaign {
    height: 45px;
    border-color: #616161;
    color: #616161;
    &:active {
      background-color: initial;
      border-color: #616161;
      color: #616161;
    }
    &:focus {
      background-color: initial;
      border-color: #616161;
      color: #616161;
    }
    &:hover {
      background-color: initial;
      border-color: #616161;
      color: #616161;
    }
  }

  .title {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;

    span {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.15px;
      text-align: left;
      color: #424242;
      margin-left: 10px;
    }
  }

  .box-details {
    text-align: start;
    gap: 10px;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px #ff5252;
    background-color: #ffebee;

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.25px;
      text-align: left;
      color: #ff5252;
    }
  }

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
  }

  .pass-word {
    text-align: start;
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.15px;
      text-align: left;
      color: #757575;
    }
  }
}
</style>
