<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="wrap-UserLevel">
        <div class="mx-auto content-inner-container border-header">
          <div class="row">
            <div
              class="
                col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12
                title-header
                pl-4
              "
            >
              <img
                class="mr-2 mt-2 member-level-icon"
                src="@/assets/images/userlevel/Stairs.svg"
                alt=""
              /><span class="header">{{ lbl['user-level-member-level'] }}</span>
              <span
                v-if="isCreate && levelStatus == 'Active'"
                class="badge-active ml-3 mt-1"
              >
                <img src="@/assets/images/userlevel/Circle.svg" alt="" />
                {{ lbl['user-level-status-active'] }}
              </span>
              <span
                v-if="isCreate && levelStatus == 'Upcoming'"
                class="badge-upcoming ml-3"
              >
                <img src="@/assets/images/userlevel/Alarm-clock.svg" alt="" />
                {{ lbl['user-level-status-upcoming'] }}
              </span>
            </div>

            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
              <b-button
                v-if="isCreate"
                variant="outline-primary"
                class="btn-outline-primary float-right"
                @click="handleEdit()"
              >
                <img
                  src="@/assets/images/userlevel/edit_orange.svg"
                  alt=""
                  class="icon-edit mr-1"
                />
                {{ lbl['user-level-edit-member-level'] }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- No Data -->
        <div :class="{ 'd-none': isCreate }">
          <div class="user-body">
            <div class="body-image-container">
              <div class="image-container">
                <img src="@/assets/images/userlevel/user_level@3x.png" alt="" />
              </div>
              <div class="desc-container">
                {{ lbl['user-level-desc-paragraph'] }}
              </div>
            </div>
          </div>
          <div class="user-footer center">
            <b-button variant="warning" class="userlevel-btn" @click="next()">
              <img src="@/assets/images/userlevel/white_plus.svg" alt="" />
              {{ lbl['user-level-create-button'] }}
            </b-button>
          </div>
        </div>

        <!-- Info -->
        <div :class="{ 'd-none': !isCreate }">
          <div class="user-body">
            <div class="body-container">
              <div class="body-main">
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-4"
                  >
                    <!-- box Member level by -->
                    <div class="box-container">
                      <div class="row">
                        <div class="col-12 title">
                          {{ lbl['user-level-member-level-by'] }}
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-12">
                          <div class="main-content view">
                            <ul
                              class="selection-container"
                              v-for="item in selectionChoice"
                              :key="item.id"
                            >
                              <li
                                class="selection-item"
                                :class="{ selected: item.id === dataLevelBy }"
                              >
                                <div class="selection-icon">
                                  <img
                                    :src="
                                      require(`../../assets/images/userlevel/${item.img}`)
                                    "
                                    alt=""
                                  />
                                </div>
                                <div class="selection-header">
                                  {{ item.name }}
                                </div>
                                <div class="selection-desc">
                                  {{ item.desc }}
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="set-display-conatainer">
                            <div class="title">
                              <strong>
                                {{ lbl['user-level-display-setting-title'] }}
                              </strong>
                              <a-tooltip placement="bottom">
                                <template slot="title">
                                  <span>
                                    {{
                                      lbl['user-level-display-setting-tooltip']
                                    }}
                                  </span>
                                </template>
                                <a-icon type="info-circle" class="info-icon" />
                              </a-tooltip>
                            </div>
                            <a-switch
                              v-model="displayUserLavel"
                              :checked-children="
                                lbl[
                                  'user-level-display-setting-title-switch-btn'
                                ]
                              "
                              :un-checked-children="
                                lbl[
                                  'user-level-display-setting-title-switch-btn'
                                ]
                              "
                              default-checked
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- box Condition -->
                    <div class="box-container">
                      <div class="row">
                        <div class="col-12 title">
                          {{ lbl['user-level-condition'] }}
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-12">
                          <div>
                            <span class="condition-title"
                              >{{ lbl['user-level-member-level-start'] }} :
                            </span>
                            {{ dateFormat(dataLevelSetting.programDate) }}
                          </div>
                          <div class="mt-4">
                            <span class="condition-title"
                              >{{ lbl['edit-date'] }} :
                            </span>
                            {{ dataUpdateDate }}
                          </div>
                          <div class="mt-4">
                            <span class="condition-title"
                              >{{ lbl['user-level-member-level-setting'] }} :
                            </span>
                            <span class="condition-value">{{
                              dataLevelSetting.selectedProgram
                            }}</span>
                            <span
                              v-if="
                                dataLevelSetting.selectedProgram == 'advance'
                              "
                            >
                              {{ lbl['user-level-member-tier-detail'] }}
                            </span>
                            <span v-else>
                              {{ lbl['user-level-reset-period'] }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="img-selected-program mt-4">
                        <img
                          v-if="dataLevelSetting.selectedProgram == 'advance'"
                          class="img-selected-program"
                          src="@/assets/images/userlevel/advance_en@3x.png"
                          alt=""
                        />
                        <img
                          v-else
                          class="img-selected-program"
                          src="@/assets/images/userlevel/basic_en@3x.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-4"
                  >
                    <!-- box Member Level -->
                    <div class="box-container">
                      <div class="row">
                        <div class="col-12 title">
                          {{ lbl['usr-level-manage-tab'] }}
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-12">
                          <a-list
                            bordered
                            v-for="(item, index) in dataMemberLevel"
                            :key="index"
                          >
                            <template>
                              <div class="d-flex w-100">
                                <div class="border-badge">
                                  <img
                                    v-if="item.url"
                                    class="badge-image"
                                    :src="`${item.url}`"
                                    alt=""
                                  />
                                  <span class="badge-name">{{
                                    item.name
                                  }}</span>
                                </div>
                              </div>
                              <div
                                v-if="index != dataMemberLevel.length - 1"
                                class="mt-3 int-point"
                              >
                                {{ stringFormat(item.start) }} -
                                {{ stringFormat(item.end) }}
                                {{
                                  dataLevelBy == 1
                                    ? lbl['user-level-number-point']
                                    : lbl['user-level-number-baht']
                                }}
                              </div>
                              <div
                                v-else-if="
                                  index == dataMemberLevel.length - 1 &&
                                  item.end != null &&
                                  item.end != '' &&
                                  item.end != 0
                                "
                                class="mt-3 int-point"
                              >
                                {{ stringFormat(item.start) }} -
                                {{ stringFormat(item.end) }}
                                {{
                                  dataLevelBy == 1
                                    ? lbl['user-level-number-point']
                                    : lbl['user-level-number-baht']
                                }}
                              </div>
                              <div v-else class="mt-3 int-point">
                                {{ stringFormat(item.start) }}
                                {{
                                  dataLevelBy == 1
                                    ? lbl['user-level-number-point']
                                    : lbl['user-level-number-baht']
                                }}
                                ++
                              </div>
                              <div class="mt-3" v-html="item.detail"></div>
                            </template>
                          </a-list>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="note">
                      {{ lbl['user-level-note-member-level'] }}
                    </div>
                    <a class="d-flex btn-end mt-3" @click="handleEnd()">
                      <img
                        src="@/assets/images/userlevel/Trash_Red.svg"
                        alt=""
                      />
                      <span>{{ lbl['user-level-end-this-campaign'] }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- History -->
    <div v-if="isHistory" class="mx-auto mt-1 content-outer-container">
      <div class="wrap-UserLevel">
        <div class="mx-auto content-inner-container border-header pt-2 pb-2">
          <div class="col-12">
            <div class="row">
              <div class="col-8 pl-0 header">
                {{ lbl['user-level-history-member-level'] }}
              </div>
              <div class="col-4 pr-0">
                <div @click="goto('HistoryLevel')">
                  <img
                    class="button-forward mt-2"
                    src="@/assets/images/userlevel/arrow_forward_color_orange.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <EndCampaign
      :show="isModalEnd"
      @changeEndCampaign="ChangeEndCampaign($event)"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import moment from 'moment'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import EndCampaign from '@/module/UserProfile/modal/EndCampaign'
import BzbsUserProfile from '@/core/UserProfile/service/BzbsUserProfile'
import Locale from '@/helper/locale.js'

export default {
  name: 'CreateUserLevel',
  components: {
    Header,
    EndCampaign,
  },
  mixins: [Mixin],
  data: function () {
    return {
      currenttitle: null,
      current: null,
      parent: null,
      isCreate: false,
      isHistory: false,
      selectionChoice: [],
      displayUserLavel: true,
      dataLevelBy: null,
      dataLevelSetting: {
        programDate: new Date(),
        selectedProgram: 'basic',
      },
      dataMemberLevel: null,
      isModalEnd: false,
      levelStatus: '',
      dataUpdateDate: null,
    }
  },
  created() {
    this.handleFooter(true)
    this.handleLoading(false)
    Account.bzbsAnalyticTracking(
      'member_level_page',
      'member_level',
      'view_member_level',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.currenttitle = this.lbl['user-customer-manage-title']
      this.current = this.lbl['user-level-member-level']
      this.selectionChoice = [
        {
          id: '1',
          name: this.lbl['user-level-earning-point'],
          desc: this.lbl['user-level-point-hunter'],
          img: 'loupe.svg',
        },
        {
          id: '2',
          name: this.lbl['user-level-total-spending'],
          desc: this.lbl['user-level-most-spending-win'],
          img: 'brightness_low.svg',
        },
      ]
      this.getDataInfo().then(() => {
        this.getHistory()
      })
    },
    handleEdit() {
      Account.bzbsAnalyticTracking(
        'member_level_page',
        'member_level',
        'click_member_level_edit',
        'on click',
      )
      localStorage.removeItem('dataUserLevel')
      let result = {
        isEdit: true,
        isUpdate: false,
      }
      localStorage.setItem('dataUserLevel', JSON.stringify(result))

      this.$router.replace({
        name: 'PreviewLevel',
      })
    },
    handleEnd() {
      console.log('handleEnd')
      Account.bzbsAnalyticTracking(
        'member_level_page',
        'member_level',
        'click_member_level_end',
        'on click',
      )
      this.isModalEnd = true
    },
    getDataInfo() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsUserProfile.getLevelList()
          .then(res => {
            if (res != null) {
              this.genDataInfo(res.data)
            } else {
              this.isCreate = false
              Account.bzbsAnalyticTracking(
                'member_level_page',
                'member_level',
                'view_member_level_blank',
                'on view',
              )
            }
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.isCreate = false
            console.log('getLevelList error', error)
            resolve(error)
          })
      })
    },
    genDataInfo(data) {
      if (data != null) {
        var dataCheck = _.filter(data, item => {
          if (item.IsUndo == true && item.Deleted == true) return item
        })

        if (dataCheck.length > 0) {
          this.isCreate = false
          Account.bzbsAnalyticTracking(
            'member_level_page',
            'member_level',
            'view_member_level_blank',
            'on view',
          )
        } else {
          this.isCreate = true
          Account.bzbsAnalyticTracking(
            'member_level_page',
            'member_level',
            'view_member_level_details',
            'on view',
          )

          if (data[0].Mode == 'spending') {
            this.dataLevelBy = '2'
          } else {
            this.dataLevelBy = '1'
          }
          this.displayUserLavel = !data[0].HideLevelOnConsumerView
          this.levelStatus = data[0].LevelStatus
          if (data[0].UpdateDate) {
            this.dataUpdateDate = moment
              .unix(data[0].UpdateDate)
              .format('DD/MM/yyyy')
          } else {
            this.dataUpdateDate = '-'
          }

          this.dataLevelSetting = {
            programDate: data[0].StartDate,
            selectedProgram: data[0].LevelType,
          }

          this.dataMemberLevel = _.map(data, (e, i) => {
            return _.extend({
              index: i + 1,
              name: e.UserLevelName,
              start: e.Value,
              end: e.MaxValue,
              detail: e.Description,
              url: e.UserLevelIconUrl,
            })
          })
        }
      } else {
        this.isCreate = false
        Account.bzbsAnalyticTracking(
          'member_level_page',
          'member_level',
          'view_member_level_blank',
          'on view',
        )
      }
    },
    getHistory() {
      return new Promise(resolve => {
        BzbsUserProfile.getHistory(null, null)
          .then(res => {
            console.log('getHistory : ', res, res.data.length)
            if (res != null) {
              if (res.data.length > 0) {
                this.isHistory = true
              } else {
                this.isHistory = false
              }
            } else {
              this.isHistory = false
            }
            resolve(res.data)
          })
          .catch(() => {
            this.isHistory = false
            resolve(null)
          })
      })
    },
    dateFormat(date) {
      let locale = Locale.getLocaleCode()
      if (locale != 'th') locale = 'en'
      moment.locale(locale)

      if (date) {
        var srtDate = moment.unix(date).format('DD/MM/')
        var year = moment.unix(date).format('YYYY')
        return srtDate + year
      } else {
        return ''
      }
    },
    stringFormat(value) {
      if (String(value).length > 3) {
        const result = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return result
      } else {
        return String(value)
      }
    },
    next() {
      Account.bzbsAnalyticTracking(
        'member_level_page',
        'member_level',
        'click_member_level_create',
        'on click',
      )
      localStorage.removeItem('dataUserLevel')
      let result = {
        levelBy: this.dataLevelBy,
        displayUserLavel: this.displayUserLavel,
      }
      localStorage.setItem('dataUserLevel', JSON.stringify(result))
      this.$router.replace({
        name: 'StepOne',
      })
    },
    goto(page) {
      Account.bzbsAnalyticTracking(
        'member_level_page',
        'member_level',
        'click_member_level_history',
        'on click',
      )
      this.$router.push({ name: page })
    },
    ChangeEndCampaign(res) {
      this.isModalEnd = false
      if (res) {
        window.location.reload()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/module/UserProfile/UI/BaseStyle.scss';
.user-body {
  display: flex;
  align-items: center;
  justify-content: center;
  .body-image-container {
    width: 45%;
    .image-container {
      margin: 20px 0;
      img {
        width: 100%;
      }
    }
    .desc-container {
      text-align: center;
    }
  }
  .title {
    font-size: 20px !important;
  }
}
.wrap-UserLevel .user-body .body-container .body-main .main-content.view {
  width: 100% !important;
}
.header {
  font-size: 34px;
  font-weight: normal;
}
.member-level-icon {
  width: 40px;
}
.user-footer {
  padding-bottom: 30px;
}
.border-header {
  padding: 30px 20px 30px 20px;
}
.img-selected-program {
  width: 100%;
}
</style>
